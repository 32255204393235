import React, { useState } from "react";
import styled from "styled-components";
import { CaretDown, CaretUp } from "phosphor-react";

const ContainerAccordion = styled.div`
  width: 100%;
  background-color: white;
  cursor: pointer;
  h1 {
    text-align: center;
    color: black;
    font-weight: bold;
    margin: 0.5rem 0.9rem;
  }
  @media (max-width: 768px) {
    padding: 0px; /* Reduce padding for smaller screens */
    h1 {
      font-size: 22px;
      margin-bottom: 10px;
    }
  }
`;
const AccordionSection = styled.section`
  &.open {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  .closed {
    margin-bottom: 20px;
  }
`;
const AccordionHeader = styled.div`
  background-color: #f7e5f1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
  @media (max-width: 768px) {
    .container-accordian h2 {
      font-size: 12px; /* Reduce font size for question titles */
      font-weight: bold;
      margin-left: -10px;
    }
  }
`;
const QuestionTitle = styled.h2`
  text-align: justify;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 12px; /* Reduce font size for question titles */
    font-weight: bold;
    margin-left: -10px;
    padding: 1px 0 0 0;
  }
`;
const AccordionPara = styled.p`
  width: 95%;
  font-weight: normal;
  text-align: justify;
  font-size: 16px;
  margin-left: 2.5%;
  @media (max-width: 768px) {
    font-size: 12px;
    font-weight: normal;
  }
`;
const AccordionButton = styled.button`
  background-color: #f7e5f1;
  color: black;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  font-size: 23px;
  height: 30px;
  width: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  @media (max-width: 768px) {
    font-size: 25px;
    height: 30px;
    width: 30px;
    margin-right: -10px;
    margin-left: 10px;
  }
`;

const VastuFYQ = () => {
  const questions = [
    {
      id: 1,
      title: "Q-1:- Does Vastu Shastra affect the life of the tenants?",
      content: `Vastu does affect the lives of people living in rental spaces. As much as it would affect the owner, it shall affect them. However, with easy Vastu remedies, people can wear these ill impacts away from their life. But, it might not be easy to implement the Vastu tips on the rental house as it would be with your own house.`,
    },
    {
      id: 2,
      title: "Q-2:- What facing is considered best when buying a flat?",
      content: `Generally, the Northeast and East direction facing the house is known best for the flat's entrance. As per Vastu Shastra, these directions attract good luck and positive vibes in the life of the people living in that space.`,
    },
    {
      id: 3,
      title: "Q-3:- Does Vastu Shastra apply on the first floor?",
      content: `Yes, Vastu applies to all floors and all types of buildings. However, people must remember to make the first floor of the place face the Southwest direction. Also, people must leave the Northern and the Eastern side open. However, if you have a building of 2-3 floors, this direction guideline doesn't apply. But, remember to make the balconies on the North and East side.`,
    },
    {
      id: 4,
      title: "Q-4:- Which direction is considered bad for the house?",
      content: `South direction is taken as an inauspicious direction for the house construction. It is so because people believe that Lord Yama lies here. According to astrology, he is considered the Lord of Death.`,
    },
    {
      id: 5,
      title: "Q-5:- What happens if the Northwest direction is cut?",
      content: `As per Vastu Vidya, a cut in the Northwest corner leads the family members into legal issues. Moreover, it can lead the natives to multiple health issues too.`,
    },
  ];
  const Question = ({ question }) => {
    const initiallyOpen = question.id === 1 || question.id === 2;
    const [open, setOpen] = useState(initiallyOpen);

    const contentWithLineBreaks = question.content
      ? question.content.split("\n").map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))
      : null;

    return (
      <AccordionSection
        className={`accordion ${open ? "open" : "closed"}`}
        id={`accordion-${question.id}`}
        onClick={() => setOpen(!open)}
      >
        <AccordionHeader
          className={open ? "open" : "closed"}
          id="accordion-two"
        >
          <QuestionTitle>{question.title}</QuestionTitle>
          <AccordionButton onClick={() => setOpen(!open)} className="btns">
            {open ? <CaretUp /> : <CaretDown />}
          </AccordionButton>
        </AccordionHeader>
        {open && <AccordionPara>{contentWithLineBreaks}</AccordionPara>}
      </AccordionSection>
    );
  };
  return (
    <>
      <ContainerAccordion>
        <h1>Frequently Asked Questions</h1>
        <div className="questions">
          {questions.map((question) => (
            <Question key={question.id} question={question} />
          ))}
        </div>
      </ContainerAccordion>
    </>
  );
};

export default VastuFYQ;
