import React, { useEffect } from "react";
import Layout from "../Layout/Layout";
import HomepageFYQ from "./HomepageFyq";
import styled from "styled-components";

const Container = styled.div`
  .body {
    width: 90%;
    margin: auto;
    text-align: justify;
  }

  .heading {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 0px;
  }
  .heading1 {
    text-align: center;
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 10px;
    color: red;
  }
  .inline {
    display: inline;
  }

  .inline-red-text {
    display: inline;
    color: red;
    cursor: pointer;
  }

  .inline-red-text a {
    display: inline;
    color: red;
    cursor: pointer;
    text-decoration: none;
  }

  .inline-red-text a:hover {
    font-weight: bold;
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  .inline {
    color: red;
    font-weight: normal;
  }
  .inline1 {
    color: black;
    font-weight: medium;
    display: inline;
  }
  li {
    margin-bottom: 5px;
  }
  p {
    line-height: 1.5;
  }
`;

const Homepage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout title="AstroSwarg">
      <Container>
        <div className="body">
          <h1 className="heading">WHY ASTROLOGY ?</h1>
          <h2 className="heading1">Astrology reveals the will of the God</h2>
          <p>
            Astrology is a predictive science with its own sets of methods,
            claims and findings that have forever inspired and allowed people
            with insights into different aspects of their life. Astrology, with
            its wows and hows, is contentful and approving enough to make people
            a believer of the same. And thankfully, it continues to do so
            despite the world shifting bases from what they believe in and what
            they don’t.
          </p>
          <p>
            If one has to go into the technicalities of astrology, it is the
            study of different cosmic objects, which are usually stars and
            planets, that have an effect on the lives of the people. You must be
            aware that there are as many as 8 planets in the solar system.
            However, If I ask an online astrologer near me about the planets in
            astrology, they will tell me that there are as many as 9 planets in
            astrology also called Navagrahas. And surprisingly, the planet
            Earth, in astrology, is not counted among the nine planets.
          </p>
          <p>
            The 9 planets in astrology are Sun (Surya), Moon (Chandra), Mars
            (Mangala), Mercury (Budha), Jupiter (Brihaspati), Venus (Shukra),
            Saturn (Shani), Rahu (north node of the moon), and Ketu (south node
            of the moon).
          </p>
          <p>
            Among these planets, some planets are called friendly planets,
            meaning the presence of them brings positivity to your life. And
            then, there are also planets that have a negative influence on
            humans. The latter would be planets like Rahu and Ketu. Their
            presence in one’s Kundli is said to bring pain and misery. However,
            there is another aspect one needs to be aware of. It’s the fact that
            the presence of Ketu in one’s horoscope is not always bad and
            similarly, the presence of Jupiter in one’s Kundli might not be the
            best every time.
          </p>
          <p>
            It all depends on which houses these planets are sitting in. If you
            ever had the opportunity to talk to an astrologer online, then s/he
            must have told you about houses in astrology and the movements of
            planets in the same. There are as many as 12 houses in Kundli. And
            all of these houses represent one thing or the other. You can check
            your free kundali online.
          </p>
          <p>
            For example, the first house, which any online astrology
            consultation would tell you is also the ascendant of the person, is
            the house of self. It represents a person’s personality and physical
            traits. Similarly, the fifth house in the Vedic horoscope, also
            known as Putra Bhava is the house of creativity, playfulness, joy,
            pleasure, and romance. If, for example, a good planet, like Jupiter,
            is camping in the fifth house, your love life will excel. Likewise,
            if Rahu is camping in the same house, then you might feel the need
            for online astrology consultation to deal with the woes.
          </p>
          <p>
            Then there are other things in astrology like elements, the Moon
            sign of an individual, numerology, tarot and so much more that is
            impossible to wind up here.
          </p>
          <h3>Online Astrology Consultation & Services</h3>
          <p>
            The online footprint of things and people has grown over the years.
            And AstroSwarg, as a brand, is using the best of it to cater online
            astrology services to anyone and everyone across the length and
            breadth of the globe. AstroSwarg, over the years, has nurtured as a
            community of the best astrologers who have their expertise in Vedic
            Astrology, tarot card reading, Vastu Shastra, numerology, Reiki
            healing, Palmistry, and many more subjects.
          </p>
          <p>
            The motive behind providing astrology predictions online is simply
            helping people save time, money, and pain to find astrologers in the
            hustle and bustle of the city lights. Besides, to ensure
            credibility, AstroSwarg has, over the years, worked extensively to
            add value to customer service. And a good chunk of credit for this
            goes to the astrologers who work for 100% customer satisfaction
            using their knowledge to deliver impeccable astrology consultancy.
          </p>
          <p>
            Surely, not everything in life should revolve around money, so we at
            AstroSwarg, besides online astrology, also organize various events
            that help one get a better understanding of online astrology
            prediction and more related themes. These events range from free
            astrology predictions sessions to live events involving Aartis and
            Pujas that happen across India at some of the most renowned temples.
            It's a way for us to connect with people.
          </p>
          <h3>Online Astrologer</h3>
          <p>
            A huge chunk of credit for what we are today goes to the force of
            online astrologers we have got on board. Astrologers come from
            different walks of life and only the best, who has a piece of
            extensive knowledge in their field, make it to this platform for
            your convenience. As the Astrologers work with us, they are
            scrutinized in the form of ratings that the customers give them. The
            better the rating, the better the service. Talking about service,
            there are multiple ways to avail the same. Either you can talk to
            the astrologer over a call or simply chat with them at your
            convenience. And guess what, the first chat with an astrologer can
            be availed at a 50% discount.
          </p>
          <p>
            The astrologers, we have on board, understand the essence of the
            5000-year old science and try their best to do justice with it.
            Besides sharing their knowledge with you, they also share videos,
            write up and other rich content with us that is available across our
            social media pages and in the blog section of our website. These
            writings on Muhurats, upcoming festivals, online astrology
            prediction will quench the thirst of your pious soul for online
            astrology knowledge.
          </p>
          <h3>Stay Updated With Daily Horoscope Predictions & Zodiac Signs</h3>
          <p>
            We as a brand are new in the online astrology consultation arena
            but, at the same time, also empowered to offer our visitors the best
            invariably. So within a short span of time, we have created a space
            to offer even the minutest details on astrological elements like
            transits, astrology daily horoscope for different zodiac signs,
            updates on upcoming auspicious dates, and much more.
          </p>
          <p>
            While astrology's daily horoscope allows one an opportunity to
            realign their day as per what the stars have for them in the box,
            having knowledge of muhurats, among other astrology services, on the
            other hand, ensures you are well updated on the best times of the
            day. So much information under one roof? Well, that’s what our
            purpose has been over the years and we continue to refine it.
          </p>
          <h3>Why Choose Our Astrology Experts?</h3>
          <p>
            In a nutshell, AstroSwarg and our experts could be your partner and
            guide when it comes to astrology. Be it a question on horoscope or
            numerology, tarot card reading or even connecting you with a foreign
            astrologer, we have practitioners to answer all your starry queries.
          </p>
          <p>
            At last, what do you need to do to get in touch with our
            astrologers? Simply, login to the AstroSwarg app, and guess what?
            There is a free astrology online session waiting for you. Once you
            have finished your free astrology predictions session, and you want
            to stay with us for longer (which you would) then you can recharge
            your wallet, and connect with live astrologers to receive instant
            astrology consultation.
          </p>
          <p>
            The best way to choose the best astrologers is to select the
            category and choose the top astrologers as per the ratings given to
            them by people like you. One can completely trust our service to be
            of supreme quality. And just in case you bump into a nag during
            astrology online consultation, you will be heard by our customer
            service team invariably.
          </p>
          <p>
            AstroSwarg connects you with the best astrologer in India. If want
            to get the best astrological consultation from one of the best
            Indian astrologers online, search for the phrase “best astrologer
            near me” on Google, and you will find Indian astrologers online.
            AstroSwarg connects you with the best astrologers in India, the USA,
            Canada, Australia or we can say that you can connect with the best
            astrologers in the world by using the AstroSwarg website and App. If
            you are looking for the best astrologers in Noida, Delhi/NCR,
            Gurgaon, etc., AstroSwarg is the best online platform for you. We
            have the best astrologers online for marriage, career, health, etc.
            AstroSwarg is your ultimate destination if you want to talk to
            astrologer online, chat with astrologers, check free kundali, match
            making, daily horoscopes, gems, and rudraksha shopping, etc.
          </p>
          <p></p>
          <p></p>
          <p></p>
          <HomepageFYQ />
        </div>
      </Container>
    </Layout>
  );
};

export default Homepage;
