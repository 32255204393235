import React, { useEffect } from "react";
import Layout from "../../../Layout/Layout.js";

const Lmlife = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout title="AstroSwarg - Vastu For Love & Married Life">
      Vastu For Love & Married Life
    </Layout>
  );
};

export default Lmlife;
